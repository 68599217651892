import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import heatechBanner from "../assets/images/banner-heatech.png";
import heatechMobileBanner from "../assets/images/heatechMobilebanner.png";
import Banner from "../assets/images/homeBanner3.png";
import { IoArrowForward } from "react-icons/io5";
import ButtonArrow from "../assets/images/button-arrow.svg";
import VideoBanner from "../assets/images/videoBanner.png";
import VideoMobileBanner from "../assets/images/videoMobileBanner.png";
import VideoPlayButton from "../assets/images/playButton.png";

import banner2 from "../assets/images/newTopBanner.png";
import mobilebanner from "../assets/images/topBanner.png";

import { CardActionArea, CardMedia, Card } from "@mui/material";

import Next from "../assets/images/nextArrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "../components/TopCarousel.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import YouTube from "react-youtube";

const Arrow = React.memo(({ className, style, onClick, isNext }) => (
  <div
    className={className}
    style={{
      ...style,
      display: "block",
      background: "white",
      zIndex: 9999,
      height: 64,
      width: 64,
      borderRadius: 100,
      borderColor: "rgba(0, 0, 0, 0.1)",
      borderWidth: 1,
      right: isNext ? 0 : undefined,
      left: isNext ? undefined : -30,
    }}
    onClick={onClick}
  >
    <img
      style={{
        position: "absolute",
        top: 21,
        left: isNext ? 26 : 24,
        transform: isNext ? undefined : "rotate(180deg)",
      }}
      alt={isNext ? "Next" : "Previous"}
      src={Next}
    />
  </div>
));

export default function SimpleSlider() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 2560);
  const [screenWidth, setScreenWidth] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const opts = {
    height: isMobile ? 500 : 400,
    width: "100%",
    margin: 0,
    playerVars: {
      autoplay: 1,
    },
  };
  const getThumbnailUrl = (videoId) => {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  const playVideo = () => {
    setIsVideoPlaying(true);
  };
  const playerRef = useRef(null);

  const onReady = (event) => {
    // Keep a reference to the player to control it later
    playerRef.current = event.target;
  };

  const stopVideo = () => {
    if (
      playerRef.current &&
      typeof playerRef.current.pauseVideo === "function"
    ) {
      playerRef.current.pauseVideo();
    }
  };
  useEffect(() => {
    return () => {
      stopVideo(); // Stop the video when unmounting
    };
  }, []);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsLargeScreen(window.innerWidth >= 2560);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // var settings = {
  //   className: "center",
  //   centerMode: true,
  //   infinite: fa,
  //   centerPadding: "10px",
  //   slidesToShow: 3,
  //   nextArrow: <Arrow isNext={true} />,
  //   prevArrow: <Arrow isNext={false} />,
  //   slidesToScroll: 1,
  //   speed: 500,
  //   // dots: isMobile,

  //   responsive: [
  //     {
  //       breakpoint: 768, // Mobile breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "0px",
  //       },
  //     },
  //     {
  //       breakpoint: 1024, // Tablet breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "0px",
  //       },
  //     },
  //     {
  //       breakpoint: 1200, // Tablet breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "0px",
  //       },
  //     },
  //     {
  //       breakpoint: 1400, // Tablet breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "0px",
  //       },
  //     },
  //     {
  //       breakpoint: 1800, // Tablet breakpoint
  //       settings: {
  //         slidesToShow: 1,
  //         centerPadding: "0px",
  //       },
  //     },
  //   ],
  // };
  const handleRedirection = (url) => {
    window.open(url, "_blank");
  };

  const settings = {
    dots: false,
    nextArrow: <Arrow isNext={true} />,
    prevArrow: <Arrow isNext={false} />,
    speed: 500,
    slidesToShow: 1,

    initialSlide: 0,
   
    // centerMode: true,
    infinite: false,
    width: "100%",
    centerPadding: "0px",
    slidesPerRow: 1,

    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          nextArrow: null,
          prevArrow: null,

          slidesToShow: 1,
          centerPadding: "10px",
        },
      },
    ],
  };
  return (
    // <div className=" container banner-class" itemScope itemType="https://schema.org/Brand">
    // <Slider sx={{ padding: isMobile ? 0 : 0 }} {...settings}>
    //   <Card
    //     sx={{
    //       maxWidth: isLargeScreen ? 1500 : 1300, // Adjust maxWidth for large screens
    //       maxHeight: isLargeScreen ? 1000 : 507, // Adjust maxHeight for large screens
    //       height: { xs: 512, md: "auto" }, // Maintain height flexibility
    //       margin: isLargeScreen ? "0 auto" : "10px", // Center the card on large screens
    //     }}
    //   >
    //     <CardActionArea sx={{":hover":{
    //       cursor:'none'
    //     }}}>
    //         <CardMedia
    //           component="img"
    //           sx={{
    //             height: screenWidth > 1980 ? "1000px" : "auto", // Adjust height conditionally
    //             width: isLargeScreen ? "100%" : "100%",
    //             objectFit: "contain", // Adjust width conditionally
    //           }}
    //           image={isMobile ? mobilebanner : Banner}
    //           alt="partner-with-us"
    //         />
    //           <button
    //           onClick={() => handleRedirection("https://uniklinger.com/Achema#partnerWithUs")}
    //           className="card-button-class d-flex items-baseline absolute left-[100px] bottom-[145px] " style={{ marginTop:'20px',padding:"18px 40px 18px 40px" }}
    //         >
    //           <span className="discoverNow mr-5">Explore</span>
    //           <span>
    //             <img
    //               src={ButtonArrow}
    //               alt="arrow"
    //             />
    //           </span>
    //         </button>
    //     </CardActionArea>
    //   </Card>
    //   <Card
    //     sx={{
    //       maxWidth: 1300, // Adjust maxWidth for large screens
    //       maxHeight: 507, // Adjust maxHeight for large screens
    //       height: { xs: 512, md: "auto" }, // Maintain height flexibility
    //       margin: "10px", // Center the card on large screens
    //     }}
    //   >
    //     <CardActionArea>
    //       <Link to={"https://uniklinger.com/Achema#partnerWithUs"}>
    //         <CardMedia
    //           component="img"
    //           sx={{
    //             height: screenWidth > 1980 ? "1000px" : "auto", // Adjust height conditionally
    //             width: isLargeScreen ? "100%" : "100%",
    //             objectFit: "contain",
    //           }}
    //           image={isMobile ? heatechMobileBanner : heatechBanner}
    //           alt="partner-with-us"
    //         />
    //       </Link>
    //     </CardActionArea>
    //   </Card>

    //   <Card
    //     sx={{
    //       maxWidth: 1300, // Adjust maxWidth for large screens
    //       maxHeight: 507, // Adjust maxHeight for large screens
    //       height: { xs: 512, md: "auto" }, // Maintain height flexibility
    //       margin: "10px", // Center the card on large screens
    //     }}
    //   >
    //     <CardActionArea>
    //       <Link to={"https://uniklinger.com/Achema#partnerWithUs"}>
    //         <CardMedia
    //           component="img"
    //           sx={{
    //             height: screenWidth > 1980 ? "1000px" : "auto", // Adjust height conditionally
    //             width: isLargeScreen ? "100%" : "100%",
    //             objectFit: "contain",
    //           }}
    //           image={isMobile ? null : VideoBanner}
    //           alt="partner-with-us"
    //         />
    //       </Link>
    //     </CardActionArea>
    //   </Card>
    // </Slider>
    // </div>

    <div className="container pt-[100px]">
      <div className="top-banner-slider">
        <Slider {...settings} sx={{ display: "flex" }} >
          {/* <div
                 onClick={() => handleRedirection("https://uniklinger.com/Achema#partnerWithUs")}

      className="top_banner_image"
     
      // onClick={() => handlePDFClick(certificate1)}
    >
      <img src={isMobile ? mobilebanner : banner2} />
    </div> */}

          <div className="top_banner_image" style={{ padding: "0 10px" }}>
            {/* <Link to={"https://uniklinger.com/Achema#partnerWithUs"}> */}
            <img src={isMobile ? heatechMobileBanner : heatechBanner} />
            {/* </Link> */}
          </div>
          <div className="top_banner_image">
            <div style={{ height: "100%", margin: 0 }}>
              {isVideoPlaying ? (
                <YouTube
                  videoId={"BEO0-bHsSOQ"}
                  // defaults -> noop
                  onPlay={() => {
                    console.log("play");
                  }} // defaults -> noop
                  onPause={() => {
                    console.log("pause");
                  }} // defaults -> noop
                  onEnd={() => {
                    setIsVideoPlaying(false);
                  }}
                  opts={opts}
                  onReady={onReady}
                />
              ) : (
                <div
                  className="thumbnail-wrapper"
                  onClick={playVideo}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    margin: 0,
                  }}
                >
                  <img
                    src={isMobile ? VideoMobileBanner : VideoBanner}
                    alt="YouTube Thumbnail"
                    // style={{ width: '100%', height: '100%' }}
                  />
                </div>
              )}
            </div>
          </div>
        </Slider>
      </div>
      {/* <div className="certifications-slider-container">
        <div className="top-banner-slider">
          <Slider {...settings} sx={{ display: "flex" }} className="mt-40">
            {/* <div
                 onClick={() => handleRedirection("https://uniklinger.com/Achema#partnerWithUs")}

      className="top_banner_image"
     
      // onClick={() => handlePDFClick(certificate1)}
    >
      <img src={isMobile ? mobilebanner : banner2} />
    </div> 

            <div className="top_banner_image">
              {/* <Link to={"https://uniklinger.com/Achema#partnerWithUs"}> */}
              {/* <img src={isMobile ? heatechMobileBanner : heatechBanner} /> */}
              {/* </Link> 
            </div>
            <div className="top_banner_image">
              <div style={{ height: "100%", margin: 0 }}>
                {isVideoPlaying ? (
                  <YouTube
                    videoId={"BEO0-bHsSOQ"}
                    // defaults -> noop
                    onPlay={() => {
                      console.log("play");
                    }} // defaults -> noop
                    onPause={() => {
                      console.log("pause");
                    }} // defaults -> noop
                    onEnd={() => {
                      setIsVideoPlaying(false);
                    }}
                    opts={opts}
                    onReady={onReady}
                  />
                ) : (
                  <div
                    className="thumbnail-wrapper"
                    onClick={playVideo}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      margin: 0,
                    }}
                  >
                    <img
                      src={isMobile ? VideoMobileBanner : VideoBanner}
                      alt="YouTube Thumbnail"
                      // style={{ width: '100%', height: '100%' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </Slider>
        </div>
      </div> */}
    </div>
  );
}
