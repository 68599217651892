import React, { useEffect, useState } from "react";
import ProductsBanner from "../assets/images/ProductBanner.png";
import ButtonArrow from "../assets/images/button-arrow.svg";
import Watermark from "../assets/images/new-watermark.png";
import MobileProductBanner from "../assets/images/productMainMobileBanner.png";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./Product.css";
import useResizeObserver from "../hooks/useResizeObserver"; // Import the custom hook
import { Breadcrumbs, Link, Typography } from "@mui/material";
import BreadcrumbsComponent from "./BreadsComponent";

const CompanyUpdates = () => {
  const [cards, setCards] = useState([]);
  const screenWidth = useResizeObserver(); // Use the custom hook
  const handleRedirection = (url) => {
    window.location.href = url;
  };

  useEffect(()=>{
   window.scrollTo(0, 0);

  },[])
  return (
    <>
      <Helmet>
        <title>Company Updates</title>
        <link
          rel="canonical"
          href="https://uniklinger.com/about-us/company-updates"
        />
      </Helmet>

      <div className="fluid-container" style={{ paddingTop: "60px" }}>
        <div
          className="banner-class relative"
          itemScope
          itemType="https://schema.org/Brand"
        >
          <img
            src={screenWidth <= 800 ? ProductsBanner : ProductsBanner}
            alt="products-banner"
            
            className="banner-image h-[300px] md:h-auto"
            itemProp="logo"
          />
          <div
            className="absolute dmsans font-bold text-white  text-[46px] top-[30%] md:top-[40%] left-[6%]"
            itemProp="name"
          >
            Corporate
          </div>
          <div
            className="absolute  dmsans font-bold text-white  text-[46px] top-[42%] md:top-[52%] left-[6%]"
            itemProp="name"
          >
            Announcement
          </div>
        </div>
        <div
          className="product-cards-container "
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: 50 }}>
            <BreadcrumbsComponent />
          </div>

          <div>
            <div
              className="dmsans"
              style={{ fontSize: 40, fontWeight: 700, marginTop: 50 }}
            >
              Corporate Announcement 2024
            </div>
          </div>

          <div>
            <Typography
              style={{ fontSize: 16, color: "#DB1F51", marginTop: 15 }}
            >
              View Form_MGT_7 Annual Return FY 2020-21 {">>"}
            </Typography>
          </div>

          <div className="company-updates d-flex flex-row md:flex-row  items-center md:items-center mt-[50px]  justify-between">
            <div className="col-md-8">
              <Typography
                sx={{ fontSize: { xs: 16, sm: 16, md: 22 }, color: "white" }}
              >
                Let us help for a proposal and analysis of your needs
              </Typography>
            </div>
            <div className="col-md-4 d-flex justify-end">
              <button
                onClick={() => {
                  handleRedirection("https://uniklinger.com/main/contact");
                }}
                style={{ fontSize: 14 }}
                className="buttonTransparent-class "
              >
                Partner With Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyUpdates;
