import React, { useEffect,useState } from "react";
import Banner from "../assets/images/industry-solution-details-banner.png";
import MobileBanner from "../assets/images/solutionMobile.png";
import PharmaSolution from "../assets/images/pharma-solutions.png";
import ButtonArrow from "../assets/images/button-arrow.svg";
import VideoSection from "../assets/images/video-section.png";
import USP1 from "../assets/images/usp-1.png";
import USP2 from "../assets/images/usp-2.png";
import USP3 from "../assets/images/usp-3.png";
import CaseStudies from "./CaseStudies";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ProductsBannerListing from "../assets/images/ProductBanner.png";
import Slider from "react-slick";
import Next from "../assets/images/nextArrow.svg";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 99,
        height: 64,
        width: 64,
        borderRadius: 100,
        borderColor: " rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
        top:205
     
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 26,
        }}
        src={Next}
      />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "white",
        zIndex: 99,
        height: 64,
        width: 64,
        borderRadius: 100,
        left: -15,
        top: 200,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
      }}
      onClick={onClick}
    >
      <img
        style={{
          position: "absolute",
          top: 21,
          left: 24,
        }}
        className="rotate-180 "
        src={Next}
      />
    </div>
  );
};
const IndustrySolutionDetails = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [screenWidth, setScreenWidth] = useState(0);
  const[cards,setCards]=useState()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  
  const solutionId = queryParams.get('solutionId');
  console.log('Solution ID:', solutionId);
  const settings = {
    dots: false,
    infinite:  true,

    nextArrow: <NextArrow />,
    prevArrow:  <PrevArrow />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding:'10px',
   
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          nextArrow:null,
          prevArrow:null,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide:1,
         infinite:false,
          centerMode:true
        },
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setScreenWidth(entry.contentRect.width);
      }
    });

    // Start observing the selected element
    resizeObserver.observe(document.body);
  }, [screenWidth]);
  useEffect(() => {
    getData();
  }, [solutionId]);
  const BaseUrl = "https://ukladmin.3mindsdigital.com";
  const getData = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/solution-details?filters[solutionId][$eq]=${solutionId}&populate[IndustryBanner]=*&populate[mainBanner]=*&populate[innovatorBanner]=*&populate[mobileMainBanner]=*&populate[USP][populate]=*`
        );
       setCards(response.data.data);
      console.log('cards',response.data.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  return (
    <>
    <Helmet>
      <title>Industry Solutions Details</title>
      <link rel="canonical" href="https://uniklinger.com" />

    </Helmet>
    <div className="container mt-[100px]">
      <div className="relative">
        <img
          src={screenWidth > 820 ?   BaseUrl.concat(cards?.[0]?.attributes?.mainBanner?.data?.attributes?.url ): MobileBanner}
          alt="banner"
          className="banner-image "
          itemScope
          itemType="https://schema.org/ImageObject"
          itemProp="image"
        />
         <div
            className="absolute product-banner-title text-white top-[30%] md:top-[25%] left-[5%]"
            itemProp="name"
          >
            {/* {cards?.[0]?.attributes?.title} */}
          </div>
          <div
            className="font-16 text-white absolute poppins  text-left top-[60%] md:top-[50%] left-[5%] w-[100%] md:w-[50%] "
            itemProp="description"
          >
                  {/* {cards?.[0]?.attributes?.titleDescription} */}

          </div>
      </div>
      <div className="pt-50 flex details-container">
        <div className="details-image-container w-2/5">
          <img
            src={BaseUrl.concat(cards?.[0]?.attributes?.innovatorBanner?.data?.attributes?.url )}
            alt="pharma-solution"
            className="w-full h-full"
            itemScope
            itemType="https://schema.org/ImageObject"
            itemProp="image"
          />
         
        </div>
        <div
          className="details-content-container w-3/5 ml-[40px] mr-[40px]"
          itemScope
          itemType="https://schema.org/Article"
        >
          <div className="details-title pb-20" itemProp="headline">
          {cards?.[0]?.attributes?.title} Innovators
          </div>
          <div className="details-description"  itemProp="articleBody">
          {cards?.[0]?.attributes?.description}

          </div>
          <div className="pt-20">
            <button className="button-class flex" itemProp="potentialAction" itemScope itemType="https://schema.org/Action">
              <span className="pr-10" itemProp="name">Enquire Now</span>
              <span>
                <img src={ButtonArrow} alt="arrow" className="mt-8" />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="pt-50 flex">
        
    
       
          <div className="relative mobileHide">
          <img
                  src={BaseUrl.concat(cards?.[0]?.attributes?.IndustryBanner?.data?.attributes?.url)}
                  alt="video-section"
          className="w-full"
          itemScope
          itemType="https://schema.org/VideoObject"
          itemProp="video"
        />
          <div style={{backgroundColor:'#00000061',width:'25%',height:'auto',padding:'15px'}}
            className="absolute text-white top-[40%]  left-[5%] poppins  text-[14px]"
            itemProp="name"
          >
            <div className="poppins text-[24px] font-bold">Process Of Industry</div>
            {cards?.[0]?.attributes?.processOfInddustry}
          </div>
          </div>
       
      </div>
      <div className="pt-50 ">
        <div className="usp-title flex">
          <span className="font-bold pr-2">Our USP’s In {" " }</span>
          <span className="poppins font-light"itemProp="about">    {cards?.[0]?.attributes?.title}</span>
        </div>
        <div
          className=" pt-30 mobile-usps "
          itemScope
          itemType="https://schema.org/ItemList"
          itemProp="mainEntity"
        >
 {cards?.[0]?.attributes?.USP?.length > 3 ? (
        // Render carousel when cards are more than 3
        <Slider {...settings} className="custom-slider">
          {cards?.[0]?.attributes?.USP?.map((usp, index) => (
            <div  key={index} className="p-6 border-primary " itemScope itemType="https://schema.org/Thing">
              <div>
                <img
                  src={BaseUrl.concat(usp?.image1?.data?.[0]?.attributes?.url)} // Change to appropriate image source
                  alt={`USP-${index + 1}`}
                  className="w-full"
                  itemProp="image"
                />
              </div>
              <div className="background-secondary h-[200px] md:h-[165px] p-20">
                <div className="usp-card-title" itemProp="name">
                  {usp?.title1}
                </div>
                <div className="usp-card-subtitle pt-10" itemProp="description">
                  {usp?.description1}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        // Render grid layout when cards are 3 or fewer
        <div className="grid grid-row-3 md:grid-cols-3 gap-[20px]">
          {cards?.[0]?.attributes?.USP?.map((usp, index) => (
            <div key={index} className="p-6 border-primary" itemScope itemType="https://schema.org/Thing">
              <div>
                <img
                  src={BaseUrl.concat(usp?.image1?.data?.[0]?.attributes?.url)} // Change to appropriate image source
                  alt={`USP-${index + 1}`}
                  className="w-full"
                  itemProp="image"
                />
              </div>
              <div className="background-secondary h-[165px] p-20">
                <div className="usp-card-title" itemProp="name">
                  {usp?.title1}
                </div>
                <div className="usp-card-subtitle pt-10" itemProp="description">
                  {usp?.description1}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
         
        </div>
      </div>
      {/* <div className="pt-50">
        {/* <div className="usp-title flex">
          <span className="font-semibold pr-10">Case</span>
          <span itemProp="about">Study</span>
        </div> */}
        {/* <CaseStudies /> 
      </div> */}
    </div>
  </>
  
  
  
  );
};

export default IndustrySolutionDetails;
